import { AxiosInstance } from 'axios'
import { Alpha3Code } from 'i18n-iso-countries'

export type eligibilityServiceCapabilities = {
  percentClient: AxiosInstance
}

export enum EligibilityStatus {
  PENDING = 'pending',
  CANCELLED = 'cancelled',
  LIVE = 'live',
  PENDING_VALIDATION_REQUEST = 'pending_validation_request',
  VALIDATION_REQUEST_FAILED = 'validation_request_failed',
  CANNOT_DEFINE_ELIGIBILITY = 'cannot_define_eligibility'
}

export type GetActivityEventsProps = {
  pageSize?: number
  cursor?: string
  sort?: string
  organisationId?: string
}

export type GetEligibilitySubscriptionsProps = {
  pageSize?: number
  cursor?: string | null
  startDate?: string | null
  endDate?: string | null
  sort?: string | null
  status?: EligibilityStatus
  partnerId?: string
  query?: string | null
}

export type PostActivityEventProps = {
  organisationId: string
  subTags: string[] | null
}

export type ActivityEvent = {
  createdAt: string
  createdBy: string
  expiresAt: string
  id: string
  organisationId: string
  subTags: string[] | null
  organisationName: string
}

export type ActivityEventsList = ActivityEvent[]

export type SubTag = {
  id: string
  name: string
  tagId: string
  description: string
  createdAt: string
  updatedAt: string | null
  deprecatedAt: string | null
  isSensitive?: boolean
  isEducation?: boolean
  isEducationStage?: boolean
  highlightedWords?: string[]
}

export type ActivityTag = {
  id: string
  name: string
  description?: string | null
  subTags: SubTag[]
  createdAt?: string
  updatedAt?: string | null
}

export type ActivityTags = ActivityTag[]

export type ActivitySubTag = {
  id: string
  name: string
  tag?: ActivityTag
  description: string
  createdAt: string
  updatedAt: string | null
  deprecatedAt: string | null
  isSensitive?: boolean
  isEducation?: boolean
  isEducationStage?: boolean
}

export type EligibilitySubscription = {
  id: string
  organisationId: string
  organisationName: string
  validationRequestId: string
  partnerId: string
  partnerName: string
  suggestedActivitySubTags: ActivitySubTag[]
  status: EligibilityStatus
  results: {
    eligibilityStatus: string
    confirmedActivitySubTags: ActivitySubTag[]
  }
  organisationSubTags?: ActivityEventSubTag[] | null
  createdAt: string
  updatedAt: string
  deletedAt: string
  countryCode: Alpha3Code
}

export type EligibilitySubscriptionList = EligibilitySubscription[]

export type ActivityEventSubTag = {
  id: string
  meetsCriteria: boolean
}
