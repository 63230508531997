import {
  UpdateBankDetailsProps,
  BankDetailsMainView,
  BankDetailsServiceCapabilities,
  GetBankDetailProps,
  BankDetailsDocumentResponse,
  BankDetailsListView,
  getBankDetailsListProps,
  UpdateBankDetailsFormProps
} from '@percent/admin-dashboard/api/actions/bankDetails/bankDetails.types'
import { PBPListResponse } from '@percent/admin-dashboard/services/pbpResponses'

export const bankDetailsService = ({ percentClient }: BankDetailsServiceCapabilities) => ({
  getBankDetailsList: (params: getBankDetailsListProps) =>
    percentClient.get<PBPListResponse<BankDetailsListView>>('v1/admin/bank-details-reviews', { params }),
  getBankDetails: ({ organisationId }: GetBankDetailProps) =>
    percentClient.get<BankDetailsMainView>(`v1/admin/organisations/${organisationId}/bank-details-review`),
  getBankDetailsDocument: ({ organisationId }: GetBankDetailProps) =>
    percentClient.get<BankDetailsDocumentResponse>(
      `v1/admin/organisations/${organisationId}/bank-details-review/document`
    ),
  updateBankDetails: ({ status, organisationId }: UpdateBankDetailsProps) =>
    percentClient.post(`v1/admin/organisations/${organisationId}/bank-details-review`, { status }),
  updateBankDetailsForm: ({ payload, organisationId }: UpdateBankDetailsFormProps) =>
    percentClient.put(`v1/admin/organisations/${organisationId}/bank-details-review`, { ...payload })
})
