import React, { useEffect, useState } from 'react'
import { getNames, alpha2ToAlpha3 } from 'i18n-iso-countries'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { RegistriesDialogProps } from './RegistriesDialog.types'
import styles from './RegistriesDialog.module.scss'
import { AcknowledgeModal, ActionModal, FormField, Modal, Select, Spacer, Text, TextInput } from '@percent/lemonade'
import { CreateRegistryProps } from '@percent/admin-dashboard/api/actions/registries/registries.types'
import { SelectOption } from 'libs/shared/ui-lemonade/src/components/select/option.types'

const nameValidation = Yup.string()
  .trim()
  .min(2, 'Must have at least 2 characters')
  .max(255, 'Must be 255 characters or less')
  .required('Required')

const validationSchema = Yup.object().shape({
  countryCode: Yup.string().trim().max(3, 'Must be 2 characters or less').required('Required'),
  name: nameValidation,
  englishName: nameValidation,
  code: Yup.string().trim().max(10, 'Must be 10 characters or less').required('Required')
})

export function RegistriesDialog({ state, onClose, refresh }: RegistriesDialogProps) {
  const { t } = useTranslation()
  const { registriesService } = useServices()
  const [successDialogState, setSuccessDialogState] = useState(false)
  const [errorDialogState, setErrorDialogState] = useState(false)
  const countriesList = Object.entries(getNames('en')).map(country => {
    return { label: country[1], value: alpha2ToAlpha3(country[0]) }
  })
  const [selectedValue, setSelectedValue] = useState<SelectOption>({
    label: '',
    value: ''
  })

  useEffect(() => {
    if (state) {
      setSuccessDialogState(false)
      setErrorDialogState(false)
    }
  }, [state])

  const [{ isLoading, errorMessage }, { apiFunc }] = useMutation(
    registriesService.createRegistry,
    () => {
      refresh()
      setSuccessDialogState(true)
    },
    () => {
      setErrorDialogState(true)
    }
  )

  const { values, errors, handleSubmit, setFieldValue, touched, resetForm, handleBlur, handleChange, dirty, isValid } =
    useFormik({
      initialValues: {
        countryCode: '',
        name: '',
        englishName: '',
        code: ''
      },
      validationSchema,
      onSubmit: async (fieldValues: CreateRegistryProps) => {
        const castValues = validationSchema.cast(fieldValues) as CreateRegistryProps

        await apiFunc({
          countryCode: castValues.countryCode,
          name: castValues.name,
          englishName: castValues.englishName,
          code: castValues.code
        })
      }
    })

  const handleOnClose = () => {
    onClose()
    resetForm()
    setSelectedValue({
      label: '',
      value: ''
    })
  }

  const handleOnErrorClose = () => {
    setErrorDialogState(false)
  }

  const successModal = successDialogState && (
    <AcknowledgeModal
      result="positive"
      title={t('dialog.addRegistry.successTitle')}
      description={t('dialog.addRegistry.successDescription', {
        name: values.name,
        country: countriesList.find(country => values.countryCode === country.value)?.label
      })}
      buttonText={t('button.done')}
      handleClose={handleOnClose}
    />
  )

  const errorModal = errorDialogState && (
    <AcknowledgeModal
      result="negative"
      title={t('dialog.addRegistry.errorTitle')}
      description={errorMessage}
      buttonText={t('button.done')}
      handleClose={handleOnErrorClose}
    />
  )

  return (
    <Modal open={state} onClose={onClose} aria-labelledby="add-registries-form-modal">
      {successModal || errorModal || (
        <ActionModal
          title={t('dialog.addRegistry.header')}
          primaryButtonText={t('button.addRegistry')}
          secondaryButtonText={t('button.cancel')}
          type="submit"
          disabled={!(dirty && isValid)}
          loading={isLoading}
          handleClose={() => {
            onClose()
            resetForm()
            setSelectedValue({
              label: '',
              value: ''
            })
          }}
          handleSubmit={handleSubmit}
          primaryBtnTestId="btn-add-registry"
          secondaryBtnTestId="btn-cancel-registry"
        >
          <Text size="small">{t('dialog.addRegistry.description')}</Text>
          <Spacer axis="vertical" size={2} />
          <form className={styles.form} onSubmit={handleSubmit}>
            <FormField
              label={t('dialog.addRegistry.countryLabel')}
              status={errors.countryCode ? 'danger' : 'default'}
              statusMessage={errors.countryCode}
            >
              <Select
                placeholder={t('dialog.addRegistry.countryLabel')}
                searchable={false}
                options={countriesList}
                onChange={event => {
                  setFieldValue('countryCode', event.value)
                  setSelectedValue({
                    label: event.label,
                    value: event.value
                  })
                }}
                defaultValue={selectedValue}
              />
            </FormField>
            <Spacer axis="vertical" size={2} />
            <FormField
              label={t('dialog.addRegistry.legalNameLabel')}
              status={touched.name && errors.name ? 'danger' : 'default'}
              statusMessage={errors.name}
              description={t('dialog.addRegistry.descriptionLegalName')}
              data-testid="registryName"
            >
              <TextInput
                name="name"
                value={values.name}
                placeholder={t('dialog.addRegistry.placeholderLegalName')}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormField>
            <Spacer axis="vertical" size={2} />
            <FormField
              label={t('dialog.addRegistry.englishNameLabel')}
              status={touched.englishName && errors.englishName ? 'danger' : 'default'}
              statusMessage={errors.englishName}
              description={t('dialog.addRegistry.descriptionEnglishName')}
              data-testid="registryEnglishName"
            >
              <TextInput
                name="englishName"
                value={values.englishName}
                placeholder={t('dialog.addRegistry.placeholderEnglishName')}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormField>
            <Spacer axis="vertical" size={2} />
            <FormField
              label={t('dialog.addRegistry.registryCodeNameLabel')}
              status={touched.code && errors.code ? 'danger' : 'default'}
              statusMessage={errors.code}
              description={t('dialog.addRegistry.descriptionCode')}
              data-testid="code"
            >
              <TextInput
                name="code"
                value={values.code}
                placeholder={t('dialog.addRegistry.placeholderCodeName')}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormField>
          </form>
        </ActionModal>
      )}
    </Modal>
  )
}
