import { StringParam, useQueryParams } from 'use-query-params'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useDidMountEffect, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { DonationsTable } from './donationsTable/DonationsTable'

export function DonationsContainer() {
  const { donationsService } = useServices()

  const [queryParams, setQueryParams] = useQueryParams({
    status: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    partnerId: StringParam
  })

  const [
    { totalResults, isLoading, dataOrNull, errorMessageOrNull },
    { nextPage, query, previousPage, refreshToFirstPage }
  ] = useQueryList(donationsService.getDonations, {
    ...queryParams
  })

  useDidMountEffect(() => query(queryParams), [queryParams, query])

  return (
    <DonationsTable
      isLoading={isLoading}
      totalResults={totalResults}
      data={dataOrNull}
      previousPage={previousPage}
      nextPage={nextPage}
      errorMessage={errorMessageOrNull}
      refreshToFirstPage={refreshToFirstPage}
      query={query}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
    />
  )
}
