import { AxiosInstance } from 'axios'
import { Alpha3Code } from 'i18n-iso-countries'

import { AgentVerificationState, AgentVerificationStatus } from '@percent/admin-dashboard/api/actions/admin/admin.types'
import { OrganisationTypes } from '@percent/admin-dashboard/constants/organisationTypes'

export type agentVerificationServiceCapabilities = {
  percentClient: AxiosInstance
}

export type activityCheckServiceCapabilities = {
  percentClient: AxiosInstance
}

export type AgentVerificationList = {
  id: string
  firstName: string
  lastName: string
  createdAt: string
  email: string
  status: AgentVerificationStatus
  userVerified: boolean
  partner: {
    name: string
  }
  validationRequestId?: string
  organisationId?: string
  title?: string
  rejectionReason?: string
  organisation: {
    name: string
    countryCode: Alpha3Code
  }
  validationRequest?: {
    name: string
  }
  emailVerifiedAt?: string
  reviewedBy?: string
  agentVerificationLogs?: AgentVerificationLog[]
}

export type GetAgentVerificationsProps = {
  page?: number
  pageSize?: number
  cursor?: string | null
  startDate?: string | null
  endDate?: string | null
  sort?: string | null
  status?: AgentVerificationStatus
  state?: AgentVerificationState
}

export type AgentVerification = {
  createdAt: string
  email: string
  emailVerifiedAt: string | null
  firstName: string
  id: string
  lastName: string
  configurationName: string | null
  language: string
  organisationId: string
  organisationWebsite: string | null
  partnerId: string
  rejectionReasonCode: AgentVerificationRejectionReasonDropdownCodes
  reviewedBy: string | null
  status: AgentVerificationStatus
  title: string
  updatedAt: string
  validationRequest: string | null
  validationRequestId: string
  validationSubmissionId: string | null
  externalActionRequired: boolean
  fraudulentFlags?: FraudulentFlag[]
  configuration?: {
    allowedOrganisations?: {
      types?: OrganisationTypes[]
    }
  }
}

export enum AgentVerificationRejectionReasonDropdownCodes {
  UserFailedPercentReview = 'user_failed_percent_review',
  Reapply = 'reapply',
  FakeEmailUsed = 'fake_email_used',
  NoOnlinePresence = 'no_online_presence',
  InvalidDocumentation = 'invalid_documentation',
  BlankCorruptedDocumentation = 'blank_corrupted_documentation',
  MismatchedInformation = 'mismatched_information',
  FakeDocumentation = 'fake_documentation',
  SuspiciousApplication = 'suspicious_application',
  Other = 'other'
}

export enum AgentVerificationRejectionReasonBackendCodes {
  ValidationRequestFailed = 'validation_request_failed',
  UserVerificationExpired = 'user_verification_expired',
  NonprofitAssociationExpired = 'nonprofit_association_expired'
}

export type AgentVerificationRejectionReasonCodes =
  | AgentVerificationRejectionReasonDropdownCodes
  | AgentVerificationRejectionReasonBackendCodes

export type RejectAgentVerificationArgs = {
  id: string
  rejectionReasonCode: AgentVerificationRejectionReasonDropdownCodes
  fraudulentFlags?: string[]
}

export type FraudulentFlagScope = 'organisation_website' | 'organisation_email' | 'agent'

export type FraudulentFlag = {
  id: string
  code: string
  scope: FraudulentFlagScope
}

export type FraudulentFlagsResponse = {
  data: FraudulentFlag[]
}

export type AgentVerificationLogType =
  | 'association_checks_approved'
  | 'association_checks_rejected'
  | 'identity_checks_approved'
  | 'identity_checks_rejected'
  | 'manually_approved'
  | 'manually_rejected'
  | 'association_checks_expired'
  | 'identity_checks_expired'
  | 'organisation_domain_change'
  | 'organisation_email_change'
  | 'domain_verified'
  | 'domain_matched'
  | 'domain_not_matched'
  | 'email_domain_matched'
  | 'email_domain_not_matched'
  | 'agent_verification_created'
  | 'agent_email_common_domain_detected'
  | 'association_email_sent'
  | 'association_email_failed_to_send'
  | 'association_email_accepted'
  | 'association_email_rejected'
  | 'association_email_expired'
  | 'inbox_verification_email_sent'
  | 'inbox_verification_failed_to_send'
  | 'inbox_verification_confirmed'
  | 'domain_verification_automated_pass'
  | 'domain_verification_flags_detected'
  | 'domain_verification_started'
  | 'validation_request_rejected'
  | 'validation_request_accepted'
  | 'found_email_previous_approval'
  | 'agent_verification_document_request_email_sent'
  | 'agent_verification_document_request_email_failed_to_send'
  | 'agent_verification_document_uploaded'

export type AgentVerificationLog = {
  id: string
  agentVerificationId: string
  type: AgentVerificationLogType
  createdAt: string
}

export type AgentVerificationLogsResponse = AgentVerificationLog[]
