import { AxiosResponse } from 'axios'

import { Organisation, Partner } from '../admin/admin.types'

import { PBPGetResponse, PBPListResponse } from '@percent/admin-dashboard/services/pbpResponses'
import {
  GetDonationRequestListProps,
  DonationRequest,
  donationRequestsServiceCapabilities,
  DonationRequestEnhanced,
  CreateDonationRequestsBatchProps,
  CancelDonationRequestProps,
  GetDonationRequestProps,
  SettleDonationRequestProps,
  DonationPerNonprofitInfo,
  DonationPerNonprofitEnhancedInfo,
  IndividualDonationInfo,
  IndividualDonationEnhancedInfo,
  GetIndividualDonationsEnhancedListProps,
  GetDonationsPerNonprofitEnhancedListProps
} from './donationRequests.types'

export const donationRequestsService = ({ percentClient }: donationRequestsServiceCapabilities) => ({
  getDonationRequestsEnhancedList: async (params: GetDonationRequestListProps) => {
    const donationRequests = await percentClient.get<PBPListResponse<DonationRequest>>('v1/admin/donation-requests', {
      params
    })

    if (donationRequests.data.data.length === 0 || donationRequests.status !== 200) {
      return donationRequests as AxiosResponse<PBPListResponse<DonationRequestEnhanced>>
    }
    const partnerIds = Array.from(new Set(donationRequests.data.data.map(({ partnerId }) => partnerId)))
    const partners = await percentClient.get<PBPListResponse<Partner>>('v1/admin/partners', { params: { partnerIds } })

    if (partners.status !== 200) {
      return partners as unknown as AxiosResponse<PBPListResponse<DonationRequestEnhanced>>
    }
    const partnerMap = new Map(partners.data.data.map(({ id, name }) => [id, name]))

    return {
      ...donationRequests,
      data: {
        ...donationRequests.data,
        data: donationRequests.data.data.map(props => ({
          ...props,
          partnerName: partnerMap.get(props.partnerId)!
        }))
      }
    }
  },
  getEnhanced: async ({
    id
  }: GetDonationRequestProps): Promise<AxiosResponse<PBPGetResponse<DonationRequestEnhanced>>> => {
    const donationRequest = await percentClient.get<PBPGetResponse<DonationRequest>>(
      `/v1/admin/donation-requests/${id}`
    )

    if (donationRequest.status !== 200) {
      return donationRequest as unknown as AxiosResponse<PBPGetResponse<DonationRequestEnhanced>>
    }

    const partner = await percentClient.get<PBPGetResponse<Partner>>(
      `/v1/admin/partners/${donationRequest.data.data.partnerId}`
    )

    if (partner.status !== 200) {
      return partner as unknown as AxiosResponse<PBPGetResponse<DonationRequestEnhanced>>
    }

    return {
      ...donationRequest,
      data: {
        ...donationRequest.data,
        data: {
          ...donationRequest.data.data,
          partnerName: partner.data.data.name
        }
      }
    }
  },
  cancel: async ({ id }: CancelDonationRequestProps) =>
    percentClient.post(`/v1/admin/donation-requests/${id}/cancel`, {
      cancelledAt: new Date().toISOString()
    }),
  createDonationRequestsBatch: (params: CreateDonationRequestsBatchProps) =>
    percentClient.post('/v1/admin/donation-requests-batch', { ...params }),
  settle: async ({ id, settledAt }: SettleDonationRequestProps) =>
    percentClient.post(`/v1/admin/donation-requests/${id}/settle`, {
      settledAt: settledAt.toISOString()
    }),
  getReport: async ({ id }: GetDonationRequestProps): Promise<string> => {
    const { data } = await percentClient.get<string>(`/v1/admin/reporting/donations?donationRequestIds=${id}`, {
      responseType: 'blob'
    })

    return data
  },
  getDonationsPerNonprofitEnhancedList:
    (donationRequestId: string) => async (params: GetDonationsPerNonprofitEnhancedListProps) => {
      const donationsPerNonprofit = await percentClient.get<PBPListResponse<DonationPerNonprofitInfo>>(
        `v1/admin/donation-requests/${donationRequestId}/organisations`,
        { params }
      )

      if (donationsPerNonprofit.data.data.length === 0 || donationsPerNonprofit.status !== 200) {
        return donationsPerNonprofit as AxiosResponse<PBPListResponse<DonationPerNonprofitEnhancedInfo>>
      }
      const organisationIds = Array.from(
        new Set(donationsPerNonprofit.data.data.map(({ organisationId }) => organisationId))
      )

      const organisations = await percentClient.get<PBPListResponse<Organisation>>(`v1/admin/organisations`, {
        params: { organisationIds, ...(params.pageSize && { pageSize: params.pageSize }) }
      })

      if (organisations.status !== 200) {
        return organisations as unknown as AxiosResponse<PBPListResponse<DonationPerNonprofitEnhancedInfo>>
      }
      const organisationsMap = new Map(organisations.data.data.map(({ id, name }) => [id, name]))

      return {
        ...donationsPerNonprofit,
        data: {
          ...donationsPerNonprofit.data,
          data: donationsPerNonprofit.data.data.map(props => ({
            ...props,
            organisationName: organisationsMap.get(props.organisationId)!
          }))
        }
      }
    },
  getIndividualDonationsEnhancedList:
    (donationRequestId: string) => async (params: GetIndividualDonationsEnhancedListProps) => {
      const individualDonations = await percentClient.get<PBPListResponse<IndividualDonationInfo>>(
        `v1/admin/donation-requests/${donationRequestId}/donations`,
        {
          params
        }
      )

      if (individualDonations.data.data.length === 0 || individualDonations.status !== 200) {
        return individualDonations as AxiosResponse<PBPListResponse<IndividualDonationEnhancedInfo>>
      }
      const organisationIds = Array.from(
        new Set(individualDonations.data.data.map(({ organisationId }) => organisationId))
      )

      const organisations = await percentClient.get<PBPListResponse<Organisation>>(`v1/admin/organisations`, {
        params: { organisationIds, ...(params.pageSize && { pageSize: params.pageSize }) }
      })

      if (organisations.status !== 200) {
        return organisations as unknown as AxiosResponse<PBPListResponse<IndividualDonationEnhancedInfo>>
      }
      const organisationsMap = new Map(organisations.data.data.map(({ id, name }) => [id, name]))

      return {
        ...individualDonations,
        data: {
          ...individualDonations.data,
          data: individualDonations.data.data.map(props => ({
            ...props,
            organisationName: organisationsMap.get(props.organisationId)!
          }))
        }
      }
    }
})
