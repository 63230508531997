import { AxiosInstance } from 'axios'

export type donationRequestsServiceCapabilities = {
  percentClient: AxiosInstance
}

export enum DonationRequestStatus {
  pending = 'pending',
  cancelled = 'cancelled',
  settled = 'settled'
}

export type DonationRequest = {
  id: string
  status: DonationRequestStatus
  foundationId: string
  partnerId: string
  start: Date
  end: Date
  amount: number
  currency: string
  createdAt: Date
  cancelledAt?: Date
  settledAt?: Date
  createdBy?: string
  cancelledBy?: string
  settledBy?: string
  reference: string
}

export type DonationRequestEnhanced = DonationRequest & {
  partnerName: string
}

export type GetDonationRequestProps = {
  id: string
}

export type GetDonationRequestListProps = {
  pageSize?: number
  cursor?: string
  status?: DonationRequestStatus
  partnerId?: string
}

export type GetIndividualDonationsEnhancedListProps = {
  page?: number
  pageSize?: number
  cursor?: string
}

export type GetDonationsPerNonprofitEnhancedListProps = {
  page?: number
  pageSize?: number
  cursor?: string
}

export type CreateDonationRequestsBatchProps = {
  partnerId: string
  startDate: string
  endDate: string
  preferredFoundationId?: string
}

export type CancelDonationRequestProps = {
  id: string
}

export type SettleDonationRequestProps = {
  id: string
  settledAt: Date
}

export type DonationPerNonprofitInfo = {
  amount: number
  currency: string
  organisationId: string
}

export type DonationPerNonprofitEnhancedInfo = DonationPerNonprofitInfo & {
  organisationName: string
}

export type IndividualDonationInfo = {
  id: string
  organisationId: string
  amount: number
  currencyCode: string
  createdAt: string
}

export type IndividualDonationEnhancedInfo = IndividualDonationInfo & {
  organisationName: string
}
