/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, Paper, Tab, Tabs, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { PartnerNameForm } from '../partnerNameForm/PartnerNameForm'
import { PartnerModuleListTable } from '../partnerModuleListTable/PartnerModuleListTable'
import { useBreadcrumbsData } from '../../Layout/breadcrumbsContext/useBreadcrumbsData/useBreadcrumbsData'
import { EligibilitySettingsContainer } from '../eligibilitySettings/EligibilitySettingsContainer'
import { SSOSettings } from '../ssoSettings/SSOSettings'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { ErrorView, Loader, TabPanel } from '@percent/admin-dashboard/common/components'
import { MemberListTable } from '@percent/admin-dashboard/app/member/MemberListTable'
import { useQuery } from '@percent/admin-dashboard/common/hooks'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import styles from './PartnerDetail.module.scss'
import { PartnerScopes } from '@percent/admin-dashboard/constants/scopes'
import { Alert } from '@percent/lemonade'

export function PartnerDetail({ match }: { match: { params: { id: string } } }) {
  const [value, setValue] = useState(0)
  const [ssoScope, setSsoScope] = useState<PartnerScopes | undefined>(undefined)
  const { setPath } = useBreadcrumbsData()
  const { id } = match.params
  const { adminService } = useServices()
  const [{ data, isLoading, errorMessage }, { refresh: partnerDetailRefresh }] = useQuery(
    adminService.queries.getPartnerDetail,
    { id }
  )

  const [{ data: scopeData, isLoading: scopeIsLoading, errorMessage: scopeErrorMessage }, { refresh: scopeRefresh }] =
    useQuery(adminService.queries.getPartnerScopeList, { partnerId: id })

  useEffect(() => {
    if (!scopeIsLoading && scopeData) {
      const scopeNames = scopeData.map(({ scopeName }) => scopeName as PartnerScopes)
      setSsoScope(
        scopeNames.find(scope => scope === PartnerScopes.SingleSignOn) ??
          scopeNames.find(scope => scope === PartnerScopes.SingleSignOnNotStrict)
      )
    }
  }, [scopeData, scopeErrorMessage, scopeIsLoading])

  const { t } = useTranslation()
  const { eligibilitiesPartnerFeatureFlag, editPartnerFeatureFlag } = useFeatureFlag()
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  const name = data?.name

  const isSSOPartner = !!ssoScope
  const isStrictSSOPartner = ssoScope === PartnerScopes.SingleSignOn

  useEffect(() => {
    setPath(name)
  }, [name, setPath])

  if (isLoading) {
    return <Loader />
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <Grid container spacing={0} component={Paper} elevation={0} className={styles.partnerDetailContainer}>
      <Grid item xs={12} className={styles.partnerDetail}>
        <Grid item xs={12}>
          <Typography className={styles.partnerName} variant="h5">
            {name}
          </Typography>
        </Grid>
        <Grid item xs={12} className={styles.partnerButtons}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            className={styles.tabs}
          >
            <Tab label={t('typography.partnerDetails')} disableRipple />
            <Tab label={t('typography.members')} disableRipple />
            {isSSOPartner && <Tab label={t('typography.singleSignOn')} disableRipple />}
            {eligibilitiesPartnerFeatureFlag && <Tab label={t('typography.eligibilitySettings')} disableRipple />}
          </Tabs>
          <div className={styles.indicator} />
        </Grid>
        <TabPanel value={value} index={0}>
          <Grid>
            <PartnerNameForm
              partnerId={id}
              partnerName={name}
              refreshApi={partnerDetailRefresh}
              editEnabled={editPartnerFeatureFlag}
            />
          </Grid>
          <Grid>
            <PartnerModuleListTable
              partnerId={id}
              partnerName={name}
              data={scopeData}
              isLoading={scopeIsLoading}
              errorMessage={scopeErrorMessage}
              refreshApi={scopeRefresh}
            />
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {isStrictSSOPartner && (
            <div data-testid="sso-warning" style={{ margin: 12, marginLeft: 0 }}>
              <Alert variant="warning" title={t('typography.partnerUsesSSO')} />
            </div>
          )}

          <Grid className={styles.memberTableGrid}>
            <MemberListTable ssoLoginOnly={isStrictSSOPartner} />
          </Grid>
        </TabPanel>
        {isSSOPartner && (
          <TabPanel value={value} index={2}>
            <Grid className={styles.sso}>
              <SSOSettings partnerId={id} />
            </Grid>
          </TabPanel>
        )}
        <TabPanel value={value} index={isSSOPartner ? 3 : 2}>
          <Grid className={styles.eligibilitySettings}>
            <EligibilitySettingsContainer partner={data} refreshPartnerApi={partnerDetailRefresh} />
          </Grid>
        </TabPanel>
      </Grid>
    </Grid>
  )
}
