import React from 'react'
import { useTranslation } from 'react-i18next'

import { CardContent } from '@percent/lemonade'
import { PaymentMethod } from '@percent/admin-dashboard/api/types'
import { ExternalLink } from '@percent/admin-dashboard/common/components/externalLink/ExternalLink'

export function CardView({ paymentMethod }: { paymentMethod: PaymentMethod }) {
  const { t } = useTranslation()

  return (
    <>
      <CardContent title={t('typography.paymentMethod')} testId="card-details-payment-method">
        {t('typography.card')}
      </CardContent>
      <CardContent title={t('typography.paymentUrl')} testId="card-details-payment-url">
        {(paymentMethod.paymentUrl && (
          <ExternalLink url={paymentMethod.paymentUrl} text={paymentMethod.paymentUrl.replace(/^https?:\/\//, '')} />
        )) ||
          t('typography.notAvailable')}
      </CardContent>
    </>
  )
}
