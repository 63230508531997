import React from 'react'
import { useTranslation } from 'react-i18next'

import { CardContent, CardDashboard, Spacer } from '@percent/lemonade'
import { Organisation, PaymentMethod } from '@percent/admin-dashboard/api/types'
import { BankDetailsView } from './BankDetailsView'
import { CardView } from './CardView'

export function PaymentDetails({
  organization,
  paymentMethod
}: {
  organization: Organisation
  paymentMethod?: PaymentMethod
}) {
  const { t } = useTranslation()

  return (
    <>
      <CardDashboard title={t('typography.paymentDetails')}>
        {paymentMethod?.method === 'card' ? (
          <CardView paymentMethod={paymentMethod} />
        ) : paymentMethod?.method === 'cheque' ? (
          <CardContent title={t('typography.paymentMethod')} testId="cheque-details-payment-method">
            {t('typography.cheque')}
          </CardContent>
        ) : organization.claimedAt ? (
          <BankDetailsView organization={organization} paymentMethod={paymentMethod} />
        ) : (
          <CardContent title={t('typography.noneSupplied')} />
        )}
      </CardDashboard>
      <Spacer size={6} axis="vertical" />
    </>
  )
}
