import { Checkbox } from '@material-ui/core'
import React from 'react'

import styles from './FieldCheckBox.module.scss'
import { FieldCheckBoxProps } from './FieldCheckBox.types'

export function FieldCheckBox({
  children,
  onChange,
  name,
  checkboxClass,
  active,
  disabled,
  indeterminate
}: FieldCheckBoxProps) {
  return (
    <div className={styles.container}>
      <Checkbox
        disableRipple
        color="default"
        name={name}
        onChange={onChange}
        className={styles[checkboxClass]}
        checked={active}
        disabled={disabled}
        role="checkbox"
        id={`checkbox-${name}`}
        indeterminate={indeterminate}
        data-testid={`checkbox-${name}`}
      />
      {children}
    </div>
  )
}
