import { Switch } from 'react-router-dom'

import { Layout } from '../Layout/Layout'
import { DocumentPreview } from '../documentPreview/DocumentPreview'

import { AuthorisedRoute } from './authorisedRoute/AuthorisedRoute'

export function AuthorisedRoutes() {
  return (
    <Switch>
      <AuthorisedRoute exact component={DocumentPreview} path="/document-preview" />
      <AuthorisedRoute path="/" component={Layout} />
    </Switch>
  )
}
