import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card, Divider, Modal, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import styles from './BankDetailsDialog.module.scss'
import { ReactComponent as CloseIcon } from '@percent/admin-dashboard/common/assets/images/close_gray.svg'
import { ErrorView, Loader, PercentButton } from '@percent/admin-dashboard/common/components'
import { BankDetailsDialogProps } from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/bankDetailsDialog/BankDetailsDialog.types'
import { ReactComponent as SuccessIcon } from '@percent/admin-dashboard/common/assets/images/success.svg'
import { Button, Spacer } from '@percent/lemonade'

export function BankDetailsDialog({ open, onClose, organisationName, refresh, formStatus }: BankDetailsDialogProps) {
  const { t } = useTranslation()
  const [isSuccess, setIsSuccess] = useState(false)
  const { bankDetailsService } = useServices()
  const { id } = useParams<{ id: string }>()

  const [{ isLoading, errorMessage }, { apiFunc }] = useMutation(bankDetailsService.updateBankDetails, async () => {
    setIsSuccess(true)
  })

  const handleUpdate = async () => {
    await apiFunc({ status: formStatus, organisationId: id })
  }

  const handleCloseModal = () => {
    onClose()
    refresh()
    setIsSuccess(false)
  }

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="bank-details-verification-approve-modal">
      <div className={styles.modal}>
        {isSuccess ? (
          <Card className={styles.wrapperSuccess}>
            <SuccessIcon className={styles.icon} />
            <Typography className={styles.successTitle}>
              {t('dialog.bankDetails.successHeader', { status: formStatus })}
            </Typography>
            <Typography>
              {t('dialog.bankDetails.successDescription', { organisationName, status: formStatus })}
            </Typography>
            <PercentButton
              title={t('button.close')}
              onClick={handleCloseModal}
              className={styles.button}
              testId="bank-details-dialog-success-close-button"
            />
          </Card>
        ) : (
          <Card className={styles.wrapper}>
            <div className={styles.titleWrapper}>
              <Typography className={styles.title}>
                {t('dialog.bankDetails.header', { status: formStatus === 'approved' ? 'Approve' : 'Reject' })}
              </Typography>
              <CloseIcon className={styles.closeIcon} onClick={onClose} />
            </div>
            <Divider className={styles.divider} />
            <Typography className={styles.textWrapper}>
              {t('dialog.bankDetails.description', { organisationName, status: formStatus })}
            </Typography>
            {errorMessage && <ErrorView errorMessage={errorMessage} />}
            <Spacer size={4} axis="vertical" />
            {isLoading ? (
              <Loader />
            ) : (
              <div className={styles.buttonWrapper}>
                <Button variant="reject" onPress={onClose} data-testId="bank-details-dialog-cancel-button">
                  {t('button.cancel')}
                </Button>
                <Spacer size={4} axis="horizontal" />
                <Button type="submit" onPress={handleUpdate} data-testid="bank-details-dialog-confirm-button">
                  {t(formStatus === 'approved' ? 'button.approve' : 'button.reject')}
                </Button>
              </div>
            )}
          </Card>
        )}
      </div>
    </Modal>
  )
}
