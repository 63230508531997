import Fuse from 'fuse.js'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useQuery } from '@percent/admin-dashboard/common/hooks/useQuery/useQuery'
import { ActivityTag } from '@percent/admin-dashboard/api/types'
import { DecoratedActivitySubTag } from './useActivityTags.types'

export const useActivityTags = ({ filterByEducation }: { filterByEducation?: boolean }) => {
  const [searchActivityTagsValue, setSearchActivityTagsValue] = useState('')
  const [searchActivityTagsResult, setSearchActivityTagsResult] = useState<ActivityTag[]>([])

  const { eligibilityService } = useServices()
  const [{ data: activityTags, isLoading: isActivityTagsLoading, errorMessage: activityTagsErrorMessage }] = useQuery(
    eligibilityService.getActivityTags,
    {}
  )

  const options = {
    includeMatches: true,
    ignoreLocation: true,
    keys: [
      { name: 'name', weight: 100 },
      { name: 'description', weight: 60 },
      { name: 'parentTagName', weight: 80 }
    ],
    threshold: 0.1
  }

  const flatActivitySubTags = activityTags?.flatMap(({ id, name, subTags }) =>
    subTags.map(subTag => ({ ...subTag, parentTagName: name, parentTagId: id }))
  )

  const activityTagsSearch = activityTags && new Fuse(flatActivitySubTags, options)

  const mapHitsToTags = (flattenedActivitySubTags: DecoratedActivitySubTag[]): ActivityTag[] => {
    const tags: Record<string, ActivityTag> = {}

    flattenedActivitySubTags?.forEach(hit => {
      tags[hit.parentTagName] = {
        subTags: !tags[hit.parentTagName] ? [hit] : [...tags[hit.parentTagName].subTags, hit],
        id: hit.parentTagId,
        name: hit.parentTagName
      }
    })

    return Object.values(tags)
  }

  const filterActivityTags = useCallback(
    (activityTagsArray: ActivityTag[]) => {
      let filteredActivityTags = activityTagsArray?.flatMap(({ id, name, subTags }) =>
        subTags.map(subTag => ({ ...subTag, parentTagName: name, parentTagId: id }))
      )

      if (filterByEducation) {
        filteredActivityTags = filteredActivityTags?.filter(({ isEducationStage }) => isEducationStage)
      }

      return mapHitsToTags(filteredActivityTags)
    },
    [filterByEducation]
  )

  const debouncedSetSearchActivityTagsResult = debounce((value: string) => setSearchActivityTagsValue(value), 600)

  useEffect(() => {
    if (searchActivityTagsValue) {
      const results = activityTagsSearch.search(searchActivityTagsValue)

      setSearchActivityTagsResult(
        mapHitsToTags(results.map(({ item }) => ({ ...item, highlightedWords: [searchActivityTagsValue] })))
      )
    } else {
      setSearchActivityTagsResult(activityTags)
    }
  }, [searchActivityTagsValue])

  useEffect(() => {
    if (activityTags) {
      setSearchActivityTagsResult(activityTags)
    }
  }, [activityTags])

  const filteredActivityTags = useMemo(
    () => filterActivityTags(searchActivityTagsResult),
    [searchActivityTagsResult, filterActivityTags]
  )

  return {
    activityTags: filteredActivityTags,
    isActivityTagsLoading,
    activityTagsErrorMessage,
    setSearchActivityTagsValue: debouncedSetSearchActivityTagsResult,
    mapHitsToTags
  }
}
