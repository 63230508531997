import React from 'react'
import { useTranslation } from 'react-i18next'

import { Badge, CardContent } from '@percent/lemonade'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useQuery } from '@percent/admin-dashboard/common/hooks'
import { ErrorView, Loader } from '@percent/admin-dashboard/common/components'
import { WireTransferType } from '@percent/admin-dashboard/api/actions/bankDetails/bankDetails.types'
import styles from './PaymentDetails.module.scss'
import { Organisation, PaymentMethod } from '@percent/admin-dashboard/api/types'
import { BankDetailStatus } from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/bankDetailsFormDialog/BankDetailsFormDialog.types'

const apiBankDetailsReviewStatusToDisplayName = {
  pending: 'Pending',
  approved: 'Approved'
}

export function BankDetailsView({
  organization,
  paymentMethod
}: {
  organization: Organisation
  paymentMethod?: PaymentMethod
}) {
  const { t } = useTranslation()
  const { bankDetailsService } = useServices()

  const [{ data: bankDetailsData, isLoading: bankDetailsLoading, errorMessage: bankDetailsError }] = useQuery(
    bankDetailsService.getBankDetails,
    {
      organisationId: organization.id
    }
  )

  const [{ data: bankDetailsDocumentData, error }, { refresh: refreshDocumentUrl }] = useQuery(
    bankDetailsService.getBankDetailsDocument,
    {
      organisationId: organization.id
    }
  )

  const handleOpenDocument = async () => {
    await refreshDocumentUrl()

    return window.open(bankDetailsDocumentData?.url, '_blank', 'noreferrer')
  }

  const wireTransferDetails = bankDetailsData?.bankAccountDetails?.wireTransferDetails

  if (bankDetailsLoading) {
    return <Loader />
  }

  if (bankDetailsError) {
    return <ErrorView errorMessage={bankDetailsError} />
  }

  const bankTransferPaymentMethod = (
    <CardContent title={t('typography.paymentMethod')} testId="bank-details-payment-method">
      {t('typography.bankTransfer')}
    </CardContent>
  )

  return bankDetailsData?.status === BankDetailStatus.APPROVED ||
    bankDetailsData?.status === BankDetailStatus.PENDING ? (
    <>
      {bankTransferPaymentMethod}
      <CardContent title={t('typography.status')} testId="bank-details-status">
        <Badge
          variant={bankDetailsData?.status === BankDetailStatus.APPROVED ? 'positive' : 'default'}
          icon={bankDetailsData?.status === BankDetailStatus.APPROVED ? 'approve' : 'clock'}
          testId="bank-details-status-badge"
        >
          {apiBankDetailsReviewStatusToDisplayName[bankDetailsData?.status as 'approved' | 'pending']}
        </Badge>
      </CardContent>
      <CardContent title={t('typography.accountHolderName')} testId="bank-details-holder">
        {bankDetailsData?.bankAccountDetails?.holderName || t('typography.notAvailable')}
      </CardContent>
      {wireTransferDetails?.type === WireTransferType.DOMESTIC && (
        <>
          <CardContent title={wireTransferDetails?.bankCodeLabel} testId="bank-details-bankcode">
            {wireTransferDetails?.bankCode || t('typography.notAvailable')}
          </CardContent>
          <CardContent title={wireTransferDetails?.accountNumberLabel} testId="bank-details-accountNumber">
            {wireTransferDetails?.accountNumber || t('typography.notAvailable')}
          </CardContent>
        </>
      )}
      {wireTransferDetails?.type === WireTransferType.IBAN && (
        <>
          <CardContent title={wireTransferDetails?.bicLabel} testId="bank-details-bic">
            {wireTransferDetails?.bic || t('typography.notAvailable')}
          </CardContent>
          <CardContent title={wireTransferDetails?.ibanLabel} testId="bank-details-iban">
            {wireTransferDetails?.iban || t('typography.notAvailable')}
          </CardContent>
        </>
      )}
      {wireTransferDetails?.type === WireTransferType.DOMESTIC_WITH_BIC && (
        <>
          {wireTransferDetails?.accountNumberLabel && (
            <CardContent title={wireTransferDetails?.accountNumberLabel} testId="bank-details-accountNumber">
              {wireTransferDetails?.accountNumber || t('typography.notAvailable')}
            </CardContent>
          )}
          {wireTransferDetails?.bicLabel && (
            <CardContent title={wireTransferDetails?.bicLabel} testId="bank-details-bic">
              {wireTransferDetails?.bic || t('typography.notAvailable')}
            </CardContent>
          )}
          {wireTransferDetails?.bankCodeLabel && (
            <CardContent title={wireTransferDetails?.bankCodeLabel} testId="bank-details-bankcode">
              {wireTransferDetails?.bankCode || t('typography.notAvailable')}
            </CardContent>
          )}
          {wireTransferDetails?.branchCodeLabel && (
            <CardContent title={wireTransferDetails?.branchCodeLabel} testId="bank-details-branchcode">
              {wireTransferDetails?.branchCode || t('typography.notAvailable')}
            </CardContent>
          )}
        </>
      )}
      <CardContent title={t('dialog.overviewBankDetails.proofDocument')}>
        {(
          <span
            role="presentation"
            className={styles.viewDocument}
            onClick={() => {
              if (!error) {
                handleOpenDocument()
              }
            }}
          >
            {t('typography.viewDocument')}
          </span>
        ) || t('typography.notAvailable')}
      </CardContent>
    </>
  ) : (
    <>
      {paymentMethod?.method === 'bank_transfer' && bankTransferPaymentMethod}
      <CardContent title={t('typography.noneSupplied')} />
    </>
  )
}
