import { Box } from '@material-ui/core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import { Button } from '@percent/lemonade'
import styles from './DonationsFiltersAndActions.module.scss'
import { useQueryList } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { DonationsFiltersAndActionsProps } from './DonationsFiltersAndActions.types'
import { dayJS } from '@percent/admin-dashboard/common/library/utility/date'
import { UploadDonationsCsv as UploadDonationsImport } from './UploadDonationsImport/UploadDonationsImport'
import { SearchableSelect } from '@percent/admin-dashboard/common/components/searchableSelect/SearchableSelect'

const THROTTLING_TIME = 500

export function DonationsFiltersAndActions({
  queryParams,
  setQueryParams,
  handleDateClick,
  handleDonationBatchCreation: handleDonationCreation,
  isUploadInProgress
}: DonationsFiltersAndActionsProps) {
  const { t } = useTranslation()
  const { adminService } = useServices()

  const defaultOption = useMemo(
    () => ({
      label: t('status.all'),
      value: ''
    }),
    [t]
  )

  const [partnersOptions, setPartnersOptions] = useState([defaultOption])
  const [partnerOptionsFilter, setPartnerOptionsFilter] = useState('')

  const [{ dataOrNull, error, isLoading }, { query }] = useQueryList(adminService.queries.getPartnerList, {
    pageSize: 25
  })

  const selectedOption = useMemo(() => {
    return partnersOptions.find(option => option.value === queryParams.partnerId) || partnersOptions[0]
  }, [partnersOptions, queryParams.partnerId])

  useEffect(() => {
    if (!isLoading && !error && dataOrNull && partnerOptionsFilter) {
      const newOptions = dataOrNull.map(({ name, id }) => ({
        label: name,
        value: id
      }))

      const uniqueOptions = [defaultOption, selectedOption, ...newOptions].reduce(
        (accumulator: { label: string; value: string }[], current) => {
          if (!accumulator.find(item => item.value === current.value)) {
            accumulator.push(current)
          }

          return accumulator
        },
        []
      )

      setPartnersOptions(uniqueOptions)
    }
  }, [dataOrNull, defaultOption, error, isLoading, partnerOptionsFilter, selectedOption])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedQuery = useCallback(
    _.debounce<any>((filter: string) => {
      query({ query: filter })
    }, THROTTLING_TIME),
    []
  )
  useEffect(() => {
    if (partnerOptionsFilter) {
      debouncedQuery(partnerOptionsFilter)
    }
  }, [partnerOptionsFilter, debouncedQuery])

  return (
    <Box className={styles.wrapper}>
      {partnersOptions ? (
        <SearchableSelect
          selectId="partner"
          loading={isLoading}
          label={t('dropdown.partner')}
          value={selectedOption}
          selectOptions={partnersOptions}
          onInputChange={(event, newValue) => setPartnerOptionsFilter(newValue.trim())}
          onChange={(event, newValue) =>
            setQueryParams({
              ...queryParams,
              partnerId: newValue?.value?.length ? newValue.value : undefined
            })
          }
          styleAsFilter
        />
      ) : null}

      <Button
        size="small"
        endIcon="dropdown-arrow-down"
        variant={queryParams.startDate ? 'primary' : 'secondary'}
        onPress={handleDateClick}
      >
        {queryParams.startDate
          ? `${dayJS(queryParams.startDate).format('ll')} - ${dayJS(queryParams.endDate).format('ll')}`
          : t('dropdown.date')}
      </Button>
      {queryParams.startDate || queryParams.endDate || queryParams.partnerId ? (
        <Button
          size="small"
          variant="tertiary"
          onPress={() => {
            setQueryParams({
              ...queryParams,
              partnerId: undefined,
              startDate: undefined,
              endDate: undefined
            })
          }}
        >
          {t('button.clear')}
        </Button>
      ) : null}
      <span className={styles.separator} />
      <UploadDonationsImport
        handleDonationBatchCreation={handleDonationCreation}
        isUploadInProgress={isUploadInProgress}
      />
    </Box>
  )
}
