import { AxiosResponse } from 'axios'
import { chunk } from 'lodash'

import { Organisation } from '../admin/admin.types'

import { PBPGetResponse, PBPListResponse } from '@percent/admin-dashboard/services/pbpResponses'
import {
  disbursementsServiceCapabilities,
  DisbursementBatch,
  GetDisbursementBatchesListProps,
  CreateDisbursementBatchProps,
  GetDisbursementBatchDetailsProps,
  Disbursement,
  DisbursementEnhanced,
  MarkDisbursementAsPaidProps,
  GetDisbursementsInBatchListProps
} from './disbursements.types'

export const disbursementsService = ({ percentClient }: disbursementsServiceCapabilities) => ({
  getDisbursementBatchesList: (params: GetDisbursementBatchesListProps) =>
    percentClient.get<PBPListResponse<DisbursementBatch>>('v1/admin/disbursement-batches', { params }),
  createDisbursementBatch: (params: CreateDisbursementBatchProps) =>
    percentClient.post('/v1/admin/disbursement-batches', { ...params }),
  getDisbursementBatchDetails: ({ id }: GetDisbursementBatchDetailsProps) =>
    percentClient.get<PBPGetResponse<DisbursementBatch>>(`v1/admin/disbursement-batches/${id}`),
  getDisbursementBatchEnhancedDisbursementsList:
    (disbursementBatchId: string) => async (params: GetDisbursementsInBatchListProps) => {
      const disbursementBatchDisbursements = await percentClient.get<PBPListResponse<Disbursement>>(
        `v1/admin/disbursement-batches/${disbursementBatchId}/disbursements`,
        { params }
      )

      if (disbursementBatchDisbursements.data.data.length === 0 || disbursementBatchDisbursements.status !== 200) {
        return disbursementBatchDisbursements as AxiosResponse<PBPListResponse<DisbursementEnhanced>>
      }
      const organisationIds = Array.from(
        new Set(disbursementBatchDisbursements.data.data.map(({ organisationId }) => organisationId))
      )

      const organisationResponses = await Promise.all(
        chunk(organisationIds, 25).map(orgChunk =>
          percentClient.get<PBPListResponse<Organisation>>(`v1/admin/organisations`, {
            params: { organisationIds: orgChunk }
          })
        )
      )

      const invalidResponse = organisationResponses.find(org => org.status !== 200)

      if (invalidResponse) {
        return invalidResponse as unknown as AxiosResponse<PBPListResponse<DisbursementEnhanced>>
      }

      const organisationsMap = new Map(
        organisationResponses
          .flatMap(orgResponse => orgResponse.data.data)
          .map(({ id, name, countryCode }) => [
            id,
            {
              organisationName: name,
              organisationCountryCode: countryCode
            }
          ])
      )

      return {
        ...disbursementBatchDisbursements,
        data: {
          ...disbursementBatchDisbursements.data,
          data: disbursementBatchDisbursements.data.data.map(props => ({
            ...props,
            organisationName: organisationsMap.get(props.organisationId)!.organisationName,
            organisationCountryCode: organisationsMap.get(props.organisationId)!.organisationCountryCode
          }))
        }
      }
    },
  markDisbursementAsPaid: ({ id, ...rest }: MarkDisbursementAsPaidProps) =>
    percentClient.post(`/v1/admin/disbursements/${id}/paid`, { ...rest }),
  cancelDisbursement: (id: string) => percentClient.post(`v1/admin/disbursements/${id}/cancel`),
  markDisbursementAsApproved: (id: string) => percentClient.post(`v1/admin/disbursements/${id}/approve`),
  markDisbursementAsSent: (id: string) => percentClient.post(`v1/admin/disbursements/${id}/in-transit`),
  markPendingDisbursementsAsApproved: (batchId: string) =>
    percentClient.post(`v1/admin/disbursement-batches/${batchId}/approve`),
  markApprovedChequeDisbursementsAsSent: (batchId: string) =>
    percentClient.post(`v1/admin/disbursement-batches/${batchId}/in-transit`)
})
