import { TableCell, TableRow, Box } from '@material-ui/core'
import { useRef, useCallback, useMemo, useState } from 'react'
import { PressEvent } from '@react-types/shared'
import { useTranslation } from 'react-i18next'

import { Badge, Icon, useToast } from '@percent/lemonade'
import {
  DateRangePopper,
  ErrorInfo,
  ErrorView,
  FoundationNameWithFlag,
  Table
} from '@percent/admin-dashboard/common/components'
import { useCurrencies, useFoundations, useMutation } from '@percent/admin-dashboard/common/hooks'
import { dayJS } from '@percent/admin-dashboard/common/library/utility/date'
import { formatAmountFromMinorUnits } from '@percent/admin-dashboard/common/utility/formatAmount/formatAmount'
import { DonationsStatusFilter, DonationsTableProps, UploadError } from './DonationsTable.types'
import {
  DonationEnhanced,
  DonationStatus,
  DonationsBatchRow
} from '@percent/admin-dashboard/api/actions/donations/donations.types'
import styles from './DonationsTable.module.scss'
import { DonationsActionsMenu } from './donationsActionsMenu/DonationsActionsMenu'
import { JsonViewDrawer } from '@percent/admin-dashboard/common/components/drawer/JsonViewDrawer'
import { DonationsFiltersAndActions } from './donationsFiltersAndActions/DonationsFiltersAndActions'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'

const cells = [
  { id: 'donation', isSortable: false, props: { width: '9%' } },
  { id: 'currency', isSortable: false, props: { width: '7.5%' } },
  { id: 'organization', isSortable: false, props: { width: '18.5%' } },
  { id: 'partner', isSortable: false, props: { width: '16.5%' } },
  { id: 'foundation', isSortable: false, props: { width: '15%' } },
  { id: 'status', isSortable: false, props: { width: '16.5%' } },
  { id: 'donationCreated', isSortable: false, props: { width: '16%' } }
]

export function DonationsTable({
  isLoading,
  totalResults,
  data,
  previousPage,
  nextPage,
  errorMessage,
  queryParams,
  setQueryParams,
  refreshToFirstPage
}: DonationsTableProps) {
  const { t } = useTranslation()
  const { foundations } = useFoundations()
  const { currencyInfo } = useCurrencies()
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const dateRangeAnchorEl = useRef<HTMLElement | null>(null)
  const [isDatePickerOpened, setIsDatePickerOpened] = useState(false)
  const [selectedDonationDetails, setSelectedDonationDetails] = useState<DonationEnhanced | undefined>(undefined)
  const [isRawViewOpen, setIsRawViewOpen] = useState(false)
  const { donationsService } = useServices()
  const { addToast } = useToast()
  const [uploadError, setUploadError] = useState<UploadError | undefined>()

  const handleMenuBtnClick = (event: React.MouseEvent<HTMLElement>, donation: DonationEnhanced) => {
    setAnchorEl(event.currentTarget)
    setSelectedDonationDetails(donation)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const tabs = useMemo(
    () => [
      t('status.all'),
      t('status.pending'),
      t('status.awaitingPayment'),
      t('status.receivedPayment'),
      t('status.settled'),
      t('status.disbursed'),
      t('status.cancelled')
    ],
    [t]
  )

  const donationsStatusValue = DonationsStatusFilter[queryParams.status as keyof typeof DonationsStatusFilter]

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue < 1) {
      setQueryParams({ ...queryParams, status: undefined })
    } else {
      setQueryParams({ ...queryParams, status: DonationsStatusFilter[newValue] })
    }
  }

  const getBadgeVariant = (status: DonationStatus) => {
    switch (status) {
      case DonationStatus.CANCELLED:
        return (
          <Badge variant="critical" icon="reject">
            {t('status.cancelled')}
          </Badge>
        )
      case DonationStatus.DISBURSED:
        return (
          <Badge variant="positive" icon="check-badge">
            {t('status.disbursed')}
          </Badge>
        )
      case DonationStatus.RECEIVED_PAYMENT:
        return (
          <Badge variant="positive" icon="check-badge">
            {t('status.receivedPayment')}
          </Badge>
        )
      case DonationStatus.REQUESTED_PAYMENT:
        return (
          <Badge variant="informative" icon="info">
            {t('status.awaitingPayment')}
          </Badge>
        )
      case DonationStatus.PENDING:
        return (
          <Badge variant="default" icon="clock">
            {t('status.pending')}
          </Badge>
        )
      case DonationStatus.SETTLED:
        return (
          <Badge variant="positive" icon="check-badge">
            {t('status.settled')}
          </Badge>
        )
      default:
        return null
    }
  }

  const handleDateClick = useCallback(
    (event: PressEvent) => {
      dateRangeAnchorEl.current = event.target as HTMLElement
      setIsDatePickerOpened(!isDatePickerOpened)
    },
    [isDatePickerOpened]
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ isLoading: isUploadInProgress }, { apiFunc: uploadDonationsBatch }] = useMutation(
    donationsService.uploadDonationsBatch,
    response => {
      addToast(t('toast.importDonationsSuccess', { numberOfDonations: response.data.data.donationsCount }), 'success')
      setQueryParams({
        status: undefined,
        partnerId: undefined,
        startDate: undefined,
        endDate: undefined
      })
      refreshToFirstPage()
      setUploadError(undefined)
    },
    (e: any) => {
      if (e.response?.data?.error) {
        setUploadError(e.response?.data?.error)
      } else {
        addToast(t('toast.importDonationsError'), 'error')
      }
    }
  )

  const handleDonationBatchCreation = useCallback(
    async (payload: DonationsBatchRow[]) => {
      await uploadDonationsBatch({ payload })
    },
    [uploadDonationsBatch]
  )

  const filtersAndActions = useMemo(
    () => (
      <Box className={styles.filtersAndActionsWrapper}>
        <DonationsFiltersAndActions
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          handleDateClick={handleDateClick}
          handleDonationBatchCreation={handleDonationBatchCreation}
          isUploadInProgress={isUploadInProgress}
        />
        {uploadError ? (
          <ErrorInfo
            header={`
            ${t('typography.donationsImportErrorTitle')} ${uploadError.message}
            `}
            reasons={uploadError.reasons}
            footer={t('typography.donationsImportErrorInstruction')}
          />
        ) : null}
      </Box>
    ),
    [t, handleDateClick, handleDonationBatchCreation, isUploadInProgress, queryParams, setQueryParams, uploadError]
  )

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <Box className={styles.tableWrapper}>
      <Table
        isLoading={isLoading}
        totalResults={totalResults}
        data={data}
        previousPage={previousPage}
        nextPage={nextPage}
        columns={cells}
        orderBy="donationCreated"
        emptyTableText={t('table.emptyDonations')}
        tabs={tabs}
        value={donationsStatusValue ?? 0}
        handleChange={handleTabChange}
        filtersContent={filtersAndActions}
      >
        {data?.map(row => {
          const foundation = row?.foundationId ? foundations?.[row?.foundationId] : undefined

          return (
            <TableRow key={row.id} className={styles.tableRow}>
              <TableCell>
                {formatAmountFromMinorUnits({
                  currencyCode: row.amount.currency,
                  value: row.amount.amount,
                  currencies: currencyInfo
                })}
              </TableCell>
              <TableCell>{row.amount.currency}</TableCell>
              <TableCell>{row.organisation.displayName ?? row.organisation.name}</TableCell>
              <TableCell>{row.partner.name}</TableCell>
              <TableCell>
                {foundation ? (
                  <FoundationNameWithFlag countryCode={foundation.countryCode} name={foundation.name} />
                ) : null}
              </TableCell>
              <TableCell>{getBadgeVariant(row.status)}</TableCell>
              <TableCell>{dayJS(row.createdAt).format('DD MMM YYYY, HH:mm')}</TableCell>
              <TableCell align="right" style={{ padding: '16px 0' }}>
                <Box
                  className={styles.menuBtn}
                  onClick={event => handleMenuBtnClick(event, row)}
                  aria-controls="actions-menu"
                  aria-haspopup="true"
                >
                  <Icon name="menu-horizontal" size="s" color="neutral400" />
                </Box>
                <DonationsActionsMenu
                  anchorEl={anchorEl}
                  isOpen={selectedDonationDetails?.id === row.id && !!anchorEl}
                  onClose={handleMenuClose}
                  handleViewRaw={() => setIsRawViewOpen(true)}
                />
              </TableCell>
            </TableRow>
          )
        })}
      </Table>
      {selectedDonationDetails ? (
        <JsonViewDrawer
          isOpen={isRawViewOpen}
          rawJsonData={selectedDonationDetails}
          onClose={() => {
            setIsRawViewOpen(false)
            setSelectedDonationDetails(undefined)
          }}
        />
      ) : null}
      <DateRangePopper
        startDate={queryParams.startDate ? new Date(queryParams.startDate) : undefined}
        endDate={queryParams.endDate ? new Date(queryParams.endDate) : undefined}
        open={isDatePickerOpened}
        anchorEl={dateRangeAnchorEl.current}
        placement="bottom-end"
        setOpen={setIsDatePickerOpened}
        setQueryParams={({ startDate, endDate }: { startDate: string; endDate: string }) => {
          setQueryParams({
            ...queryParams,
            startDate: dayJS(startDate).toISOString(),
            endDate: dayJS(endDate).toISOString()
          })
        }}
      />
    </Box>
  )
}
