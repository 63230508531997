import { TableCell, TableRow, Box, Typography } from '@material-ui/core'
import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { CreateDonationRequestsBatchModal } from '../createDonationRequestsBatchModal/CreateDonationRequestsBatchModal'

import { Icon, Button, Copy } from '@percent/lemonade'
import { ErrorView, FoundationNameWithFlag, Table } from '@percent/admin-dashboard/common/components'
import { useFoundations, useCurrencies } from '@percent/admin-dashboard/common/hooks'
import { DonationRequestFilter, DonationRequestTableProps } from './DonationRequestsTable.types'
import styles from './DonationRequestsTable.module.scss'
import { dayJS } from '@percent/admin-dashboard/common/library/utility/date'
import { formatAmountFromMinorUnits } from '@percent/admin-dashboard/common/utility/formatAmount/formatAmount'
import { DonationRequestStatus } from '@percent/admin-dashboard/api/actions/donationRequests/donationRequests.types'
import { Info } from '@percent/admin-dashboard/common/components/info/Info'
import { config } from '@percent/admin-dashboard/config'

const cells = [
  { id: 'totalAmount', isSortable: false },
  { id: 'donationRequestReference', isSortable: false },
  { id: 'currency', isSortable: false },
  { id: 'foundation', isSortable: false },
  { id: 'partner', isSortable: false },
  { id: 'dateRangeUtc', isSortable: false },
  { id: 'donationRequestCreated', isSortable: false }
]

const { donationRequestPreviewUrl } = config.donation

export function DonationRequestsTable({
  isLoading,
  totalResults,
  data,
  previousPage,
  nextPage,
  errorMessage,
  query,
  status,
  refreshToFirstPage
}: DonationRequestTableProps) {
  const { t } = useTranslation()
  const { foundations } = useFoundations()
  const { currencyInfo } = useCurrencies()
  const { push } = useHistory()
  const [isCreateDonationRequestsBatchModalOpen, setIsCreateDonationRequestsBatchModalOpen] = useState(false)

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) =>
    query({ status: DonationRequestFilter[newValue] as DonationRequestStatus })

  const handlePressCreateDonationBatch = () => {
    if (refreshToFirstPage) {
      refreshToFirstPage()
    }
    setIsCreateDonationRequestsBatchModalOpen(true)
  }

  const tabs = useMemo(() => [t('status.pending'), t('status.settled'), t('status.cancelled')], [t])

  const handleRowClick = (id: string) => () => push(`/donation-requests/${id}`)

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <>
      <Table
        isLoading={isLoading}
        totalResults={totalResults}
        data={data}
        value={DonationRequestFilter[status]}
        previousPage={previousPage}
        nextPage={nextPage}
        columns={cells}
        handleChange={handleTabChange}
        emptyTableText={t('table.emptyDonationRequests')}
        tabs={tabs}
        title={
          <div className={styles.tableHeader}>
            <Info>
              <Typography variant="body2" style={{ display: 'inline' }}>
                {t('typography.donationRequestPreviewLink')}&nbsp;
              </Typography>
              <a
                style={{
                  pointerEvents: !donationRequestPreviewUrl ? 'none' : 'auto'
                }}
                target="_blank"
                rel="noopener noreferrer"
                href={donationRequestPreviewUrl}
              >
                {t('typography.here')}
              </a>
            </Info>
            <Box className={styles.tableHeaderBox}>
              <Button onPress={handlePressCreateDonationBatch} size="small">
                {t('button.createDonationRequestBatch')}
              </Button>
            </Box>
          </div>
        }
      >
        {data?.map(row => {
          const foundation = foundations?.[row.foundationId]

          return (
            <TableRow className={styles.tableRow} key={row.id} onClick={handleRowClick(row.id)}>
              <TableCell>
                {formatAmountFromMinorUnits({
                  currencyCode: row.currency,
                  value: row.amount,
                  currencies: currencyInfo
                })}
              </TableCell>
              <TableCell
                onClick={e => {
                  e.stopPropagation()
                }}
              >
                <Typography variant="body2" className={styles.referenceCell}>
                  <Copy text={row.reference}>{row.reference}</Copy>
                </Typography>
              </TableCell>
              <TableCell>{row.currency}</TableCell>
              {foundation && (
                <TableCell>
                  <FoundationNameWithFlag countryCode={foundation.countryCode} name={foundation.name} />
                </TableCell>
              )}
              <TableCell>{row.partnerName}</TableCell>
              <TableCell>
                {`${dayJS.utc(row.start).format('DD MMM YYYY')} - ${dayJS.utc(row.end).format('DD MMM YYYY')}`}
              </TableCell>
              <TableCell>{dayJS(row.createdAt).format('DD MMM YYYY, HH:mm')}</TableCell>
              <TableCell align="right" style={{ padding: '16px 0' }}>
                <Icon name="chevron-right" size="s" />
              </TableCell>
            </TableRow>
          )
        })}
      </Table>
      <CreateDonationRequestsBatchModal
        open={isCreateDonationRequestsBatchModalOpen}
        onClose={() => setIsCreateDonationRequestsBatchModalOpen(false)}
        onSuccess={refreshToFirstPage}
      />
    </>
  )
}
