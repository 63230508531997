/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { useDebounce } from 'usehooks-ts'
import { identity } from 'lodash'
import { useTranslation } from 'react-i18next'

import { AcknowledgeModal, ActionModal, AsyncSelect, FormField, Modal, Select, Spacer } from '@percent/lemonade'
import { SelectOption } from 'libs/shared/ui-lemonade/src/components/select/option.types'
import { UpdateOrganisationModalProps } from './UpdateOrganisationModal.types'
import { useCountries, useMutation, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { mapCountriesToSelectOptions } from '@percent/admin-dashboard/common/utility/mapCountriesToSelectOptions'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { createShortLink } from '@percent/utility'
import { Organisation } from '@percent/admin-dashboard/api/types'
import { PaginationItem } from 'libs/shared/ui-lemonade/src/components/pagination/pagination-item'

export function UpdateOrganisationModal({
  open,
  onClose,
  refresh,
  donationMatchRequestId
}: UpdateOrganisationModalProps) {
  const { t } = useTranslation()
  const { adminService, donationMatchingRequestService } = useServices()
  const [errorModalState, setErrorModalState] = useState(false)
  const [countryCode, setCountryCode] = useState<string | undefined>(undefined)
  const [selectedOrgName, setSelectedOrgName] = useState('')
  const [{ isLoading, success }, { apiFunc }] = useMutation(
    donationMatchingRequestService.updateDonationMatchRequestOrganisation,
    undefined,
    () => setErrorModalState(true)
  )

  const countries = useCountries()
  const countriesOptions = useMemo(() => {
    return mapCountriesToSelectOptions(countries)
  }, [countries])

  const [query, setQuery] = useState('')

  const debouncedQuery = useDebounce(query, 300)

  const [{ dataOrNull: searchData, isLoading: isFetching }, { query: searchQuery }] = useQueryList(
    adminService.getOrganisations,
    { query },
    false
  )

  const getOrganisationDescription = useCallback(
    (organisation: Organisation): string => {
      return [
        organisation.website ? createShortLink(organisation.website) : undefined,
        `${t('typography.id')}: ${organisation.registryId}`,
        organisation.countryCode
      ]
        .filter(identity)
        .join(' | ')
    },
    [t]
  )

  const searchResults: SelectOption[] = useMemo(() => {
    return searchData
      ? searchData.map(organisation => ({
          value: organisation.id,
          label: organisation.name,
          description: getOrganisationDescription(organisation)
        }))
      : []
  }, [searchData, getOrganisationDescription])

  useEffect(() => {
    if (query.length >= 1) {
      searchQuery({
        query: debouncedQuery,
        countryCode
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery, countryCode])

  const formik = useFormik({
    initialValues: { organisationId: '' },
    validationSchema: () => Yup.object().shape({ organisationId: Yup.string().required() }),
    onSubmit: async ({ organisationId }) => {
      await apiFunc({
        donationMatchRequestId,
        organisationId
      })
    }
  })

  const { handleSubmit, setFieldValue, setFieldTouched, touched, errors, dirty, isValid, resetForm } = formik

  const handleOnSuccess = () => {
    onClose()
    refresh()
  }

  const handleOnError = () => {
    setErrorModalState(false)
    onClose()
    resetForm()
  }

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="donation-match-request-update-modal">
      {errorModalState ? (
        <AcknowledgeModal
          result="negative"
          title={t('dialog.donationMatchRequestUpdateOrganisation.error.title')}
          description={t('dialog.donationMatchRequestUpdateOrganisation.error.description')}
          buttonText={t('button.done')}
          handleClose={handleOnError}
        />
      ) : success ? (
        <AcknowledgeModal
          result="positive"
          title={t('dialog.donationMatchRequestUpdateOrganisation.success.title')}
          description={t('dialog.donationMatchRequestUpdateOrganisation.success.description', {
            orgName: selectedOrgName
          })}
          buttonText={t('button.done')}
          handleClose={handleOnSuccess}
        />
      ) : (
        <ActionModal
          title={t('dialog.donationMatchRequestUpdateOrganisation.title')}
          primaryButtonText={t('dialog.donationMatchRequestUpdateOrganisation.button')}
          secondaryButtonText={t('button.cancel')}
          variant={isValid && dirty ? 'primary' : 'secondary'}
          disabled={!(isValid && dirty && !isLoading)}
          loading={isLoading}
          handleClose={() => {
            onClose()
            resetForm()
          }}
          handleSubmit={handleSubmit}
          primaryBtnTestId="update-match-request"
          secondaryBtnTestId="cancel-update-match-request"
          aria-labelledby="update-match-request-modal"
        >
          <form onSubmit={handleSubmit}>
            <FormikProvider value={formik}>
              <FormField
                label={t('dialog.donationMatchRequestUpdateOrganisation.form.country.label')}
                data-testid="selectCountry"
              >
                <Select
                  placeholder={t('dialog.donationMatchRequestUpdateOrganisation.form.country.placeholder')}
                  searchable
                  options={countriesOptions}
                  onChange={event => setCountryCode(event.value)}
                />
              </FormField>
              <Spacer size={4} axis="vertical" />
              <FormField
                label={t('dialog.donationMatchRequestUpdateOrganisation.form.legalName.label')}
                status={errors.organisationId && touched.organisationId ? 'danger' : 'default'}
                statusMessage={errors.organisationId}
                data-testid="organisationName"
                necessity="required"
              >
                <AsyncSelect
                  placeholder={t('dialog.donationMatchRequestUpdateOrganisation.form.legalName.placeholder')}
                  onChange={e => {
                    setFieldTouched('organisationId')
                    setFieldValue('organisationId', e?.value ?? '')
                    setSelectedOrgName(e?.label ?? '')
                  }}
                  options={searchResults}
                  setQuery={e => {
                    setQuery(e)
                    setFieldTouched('organisationId')
                  }}
                  query={query}
                  loading={(touched.organisationId ?? false) && isFetching}
                  noResultsFoundText={t('typography.noResultsFound')}
                />
              </FormField>
            </FormikProvider>
          </form>
        </ActionModal>
      )}
    </Modal>
  )
}
