import { Box, DialogContent, Typography } from '@material-ui/core'
import { useRef, useState, useMemo, useEffect, useCallback } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import { DateRangePopper, Dialog, FieldInput, FoundationNameWithFlag } from '@percent/admin-dashboard/common/components'
import { useFoundations, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { Button, Icon } from '@percent/lemonade'
import { useToast } from '@percent/admin-dashboard/containers/toast/ToastContext'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { CreateDonationRequestsBatchModalProps } from './CreateDonationRequestsBatchModal.types'
import styles from './CreateDonationRequestsBatchModal.module.scss'
import { dayJS, getUTCDayStart } from '@percent/admin-dashboard/common/library/utility/date'
import { SearchableSelect } from '@percent/admin-dashboard/common/components/searchableSelect/SearchableSelect'
import { FieldDropDown } from '@percent/admin-dashboard/common/components/fieldDropDown/FieldDropDown'

const THROTTLING_TIME = 500

export function CreateDonationRequestsBatchModal({ open, onClose, onSuccess }: CreateDonationRequestsBatchModalProps) {
  const { t } = useTranslation()
  const { foundations } = useFoundations()
  const { donationRequestsService, adminService } = useServices()
  const addToast = useToast()
  const [isDatePickerOpened, setIsDatePickerOpened] = useState(false)
  const defaultDateRange = {
    startDate: dayJS().subtract(1, 'day').toDate(),
    endDate: dayJS().subtract(1, 'day').toDate()
  }

  const {
    values,
    setFieldValue,
    setValues,
    errors,
    handleSubmit,
    isValid,
    isSubmitting,
    dirty,
    resetForm,
    touched,
    setFieldTouched
  } = useFormik<{
    startDate: undefined | Date
    endDate: undefined | Date
    partnerId: string
    preferredFoundationId: string
  }>({
    initialValues: {
      startDate: undefined,
      endDate: undefined,
      partnerId: '',
      preferredFoundationId: ''
    },

    validationSchema: () =>
      Yup.object().shape({
        startDate: Yup.date().required('Required'),
        endDate: Yup.date().required('Required'),
        partnerId: Yup.string().required(),
        preferredFoundationId: Yup.string()
      }),
    onSubmit: async ({ partnerId, startDate, endDate, preferredFoundationId }) => {
      try {
        await donationRequestsService.createDonationRequestsBatch({
          partnerId,
          startDate: getUTCDayStart(startDate!).toISOString(),
          endDate: dayJS(endDate!).endOf('day').toISOString(),
          preferredFoundationId: preferredFoundationId || undefined
        })

        onSuccess()
        addToast(t('toast.createDonationRequestsBatchSuccess'), 'success')
        handleClose()
      } catch (e) {
        if (e?.response?.data?.error?.code === 'donation_request/no_donations_found') {
          addToast(t('toast.createDonationRequestsBatchNoDonationsError'), 'error')
        } else {
          addToast(t('toast.createDonationRequestsBatchError'), 'error')
        }
      }
    }
  })

  const defaultOption = useMemo(
    () => ({
      label: t('dialog.createDonationRequestsBatch.form.searchPartners'),
      value: ''
    }),
    [t]
  )

  const [partnersOptions, setPartnersOptions] = useState([defaultOption])
  const [partnerOptionsFilter, setPartnerOptionsFilter] = useState('')

  const [{ dataOrNull, error, isLoading }, { query }] = useQueryList(adminService.queries.getPartnerList, {
    pageSize: 25
  })

  const foundationsOptions = useMemo(() => {
    return Object.values(foundations || {}).map(foundation => ({
      title: <FoundationNameWithFlag countryCode={foundation.countryCode} name={foundation.name} />,
      value: foundation.id
    }))
  }, [foundations])

  const selectedOption = useMemo(() => {
    return partnersOptions.find(option => option.value === values.partnerId) || partnersOptions[0]
  }, [partnersOptions, values.partnerId])

  useEffect(() => {
    if (!isLoading && !error && dataOrNull && partnerOptionsFilter) {
      const newOptions = dataOrNull.map(({ name, id }) => ({
        label: name,
        value: id
      }))

      const uniqueOptions = [defaultOption, selectedOption, ...newOptions].reduce(
        (accumulator: { label: string; value: string }[], current) => {
          if (!accumulator.find(item => item.value === current.value)) {
            accumulator.push(current)
          }

          return accumulator
        },
        []
      )

      setPartnersOptions(uniqueOptions)
    }
  }, [dataOrNull, defaultOption, error, isLoading, partnerOptionsFilter, selectedOption])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedQuery = useCallback(
    _.debounce<any>((filter: string) => {
      query({ query: filter })
    }, THROTTLING_TIME),
    []
  )
  useEffect(() => {
    if (partnerOptionsFilter) {
      debouncedQuery(partnerOptionsFilter)
    }
  }, [partnerOptionsFilter, debouncedQuery])

  const anchorEl = useRef<HTMLElement | null>(null)

  const handleClose = () => {
    resetForm()
    onClose()
  }

  const handleDateRangeClick = (elem: HTMLElement) => {
    setFieldTouched('startDate', true)
    setFieldTouched('endDate', true)
    anchorEl.current = elem
    setIsDatePickerOpened(true)
  }

  return (
    <Dialog headerTitle={t('dialog.createDonationRequestsBatch.header')} openModal={open} onClose={handleClose}>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box className={styles.formFieldWrapper}>
            {partnersOptions ? (
              <SearchableSelect
                selectId="partner"
                loading={isLoading}
                label={t('dropdown.partner')}
                value={selectedOption}
                selectOptions={partnersOptions}
                onInputChange={(event, newValue) => setPartnerOptionsFilter(newValue.trim())}
                onChange={(event, newValue) => {
                  setFieldTouched('partnerId')
                  setFieldValue('partnerId', newValue?.value)
                }}
                disableDefaultOption
              />
            ) : null}
          </Box>
          <Box className={styles.formFieldWrapper}>
            {foundationsOptions && (
              <FieldDropDown
                selectTitle={t('dialog.createDonationRequestsBatch.form.foundation')}
                placeholder={t('dialog.createDonationRequestsBatch.form.selectFoundation')}
                valueArray={foundationsOptions}
                initialValue={values.preferredFoundationId}
                onClick={e => {
                  setFieldValue('preferredFoundationId', e.target.value)
                }}
                isClearable
              />
            )}
          </Box>
          <Box className={styles.formFieldWrapper}>
            <DateRangePopper
              open={isDatePickerOpened}
              anchorEl={anchorEl.current}
              placement="bottom-end"
              setOpen={setIsDatePickerOpened}
              setQueryParams={({ startDate, endDate }: { startDate: string; endDate: string }) => {
                setValues({
                  startDate: dayJS(startDate).toDate(),
                  endDate: dayJS(endDate).toDate(),
                  partnerId: values.partnerId,
                  preferredFoundationId: values.preferredFoundationId
                })
              }}
              maxDate={defaultDateRange.endDate}
              startDate={defaultDateRange.startDate}
              endDate={defaultDateRange.endDate}
            />
            <Box className={styles.datePickerWrapper} onClick={e => handleDateRangeClick(e.target as HTMLElement)}>
              <FieldInput
                name="date-range"
                label={t('dialog.createDonationRequestsBatch.form.date')}
                placeHolder={t('dialog.createDonationRequestsBatch.form.selectDateRange')}
                value={
                  values.startDate && values.endDate
                    ? `${dayJS(values.startDate).format('DD MMM YYYY')} - ${dayJS(values.endDate).format(
                        'DD MMM YYYY'
                      )}`
                    : 'Select date range'
                }
                touched={touched.startDate || touched.endDate}
                error={errors.startDate || errors.endDate}
                readOnly
              />
              <Icon name="chevron-down" size={4} color="gray600" />
            </Box>
          </Box>
          <Box className={styles.info}>
            <Icon name="info" color="gray500" size={6} />
            <Typography variant="body2">{t('dialog.createDonationRequestsBatch.info')}</Typography>
          </Box>
          <Box className={styles.buttonsWrapper}>
            <Button size="large" type="submit" disabled={!(isValid && dirty) || isSubmitting} loading={isSubmitting}>
              {t('button.create')}
            </Button>
            <Button onPress={handleClose} variant="secondary" size="large" type="button">
              {t('button.cancel')}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  )
}
