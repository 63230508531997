import React from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { StylesProvider } from '@material-ui/core'
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { parse, stringify } from 'query-string'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'
import { SplitFactory } from '@splitsoftware/splitio-react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DayJsUtils from '@date-io/dayjs'

import { Theme } from '@percent/admin-dashboard/app/theme/Theme'
import { AuthContextController } from '@percent/admin-dashboard/context/auth'
import { splitSdkConfig } from '@percent/admin-dashboard/app/providers/SplitSdkProvider'
import { WithAxios } from '@percent/admin-dashboard/common/HOC/withAxios/WithAxios'
import { environments } from '@percent/admin-dashboard/constants/environments'
import { environment } from '@percent/admin-dashboard/config'
import { worker } from '@percent/admin-dashboard/mocks/browser'
import { App } from './app/app-entry'
import { ServiceContextContainer } from './containers/service/ServiceContextContainer'
import { LemonadeProvider } from '@percent/lemonade'
import { FoundationsContextController } from './context/foundations'
import { CurrenciesContextController } from './context/currencies'
import { Fallback } from './common/components/fallback/Fallback'
import { logger } from './common/utility/logger'
import { CountriesProvider } from './context/countries'

if ([environments.PRODUCTION, environments.SANDBOX].includes(environment as string)) {
  Sentry.init({
    dsn: process.env.NX_SENTRY_DSN,
    autoSessionTracking: true,
    environment: process.env.NX_REACT_APP_ENV,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0.33
  })
}

if (process.env.NODE_ENV === 'development') {
  worker.start()
}

const container = document.getElementById('root')

const root = createRoot(container!)

root.render(
  <Sentry.ErrorBoundary fallback={<Fallback />} onError={(error: Error) => logger(error)}>
    <Router>
      <LemonadeProvider>
        <SplitFactory config={splitSdkConfig}>
          <QueryParamProvider
            adapter={ReactRouter5Adapter}
            options={{
              searchStringToObject: parse,
              objectToSearchString: stringify
            }}
          >
            <StylesProvider injectFirst>
              <ServiceContextContainer>
                <AuthContextController>
                  <FoundationsContextController>
                    <CurrenciesContextController>
                      <CountriesProvider>
                        <Theme>
                          <MuiPickersUtilsProvider utils={DayJsUtils}>
                            <WithAxios>
                              <LemonadeProvider>
                                <App />
                              </LemonadeProvider>
                            </WithAxios>
                          </MuiPickersUtilsProvider>
                        </Theme>
                      </CountriesProvider>
                    </CurrenciesContextController>
                  </FoundationsContextController>
                </AuthContextController>
              </ServiceContextContainer>
            </StylesProvider>
          </QueryParamProvider>
        </SplitFactory>
      </LemonadeProvider>
    </Router>
  </Sentry.ErrorBoundary>
)
